import React from 'react';
export let PageControlPosition;
(function (PageControlPosition) {
  PageControlPosition["OVER"] = "over";
  PageControlPosition["UNDER"] = "under";
})(PageControlPosition || (PageControlPosition = {}));
// @ts-ignore
class CarouselTypesForDocs extends React.Component {
  // eslint-disable-line
  static displayName = 'Carousel';
  render() {
    return null;
  }
}